
import api from '@/api/co.api'
import app from '@/api/co.app'
import notice from '@/api/co.notice'
import cookies from '@/api/co.libs.cookies'

export default {
  data () {
    return {
      navHome: {
        text: '首页',
        disabled: false,
        href: '/',
      },
      navSelf: {
        text: '',
        disabled: false,
        href: '',
      },
      navCurrent: {
        text: '',
        disabled: true,
        href: '',
      },
      banApp: {
        assistColor: 'primary',
        divisible: false,
        crumbs: []
      },
      classify: {
        height: 500,
        points: {
          xs: 150,
          sm: 300,
          md: 500,
          lg: 650,
          xl: 650
        },
        cycle: true,
        interval: 9000,
        hideDelimiters: false,
        showArrows: true
      },
      banTopicId: '',
      banCategoryId: '',
      banParams: null,
      banItems: [],
      banTimer: null
    }
  },
  computed: {
    wdHideDelimiters () {
      if (this.banItems.length > 1) {
        return this.classify.hideDelimiters
      } else {
        return true
      }
    },
    wdShowArrows () {
      if (this.banItems.length > 1) {
        return this.classify.showArrows
      } else {
        return false
      }
    }
  },
  methods: {
    configClassify (topic, defValue = {}) {
      const pattern = topic.pattern || {}
      const cls = pattern.classify || defValue || {}
      Object.assign(this.classify, cls)
    },
    setCrumbSelf (self = {}) {
      if (!self) {
        return
      }

      if (!self.title) {
        return
      }

      this.navSelf.text = self.title
      this.navSelf.href = self.href
      this.navSelf.disabled = self.disabled === true
    },
    setCrumbs (navigation = null, disabled = true) {
      // console.log('setCrumbs navigation: %o', navigation)
      this.banApp.crumbs = []
      if (navigation && navigation.title) {
        this.navCurrent.text = navigation.title
        this.navCurrent.disabled = disabled
        this.navCurrent.href = navigation.href || ''

        this.navSelf.disabled = false

        this.banApp.crumbs.push(
          this.navHome,
          this.navSelf,
          this.navCurrent
        )
      } else {
        this.navSelf.disabled = true
        this.banApp.crumbs.push(
          this.navHome,
          this.navSelf
        )
      }

      this.banApp.divisible = true
    },
    initBannerParams ({
      siteId = app.init.siteId,
      topicId = '',
      categoryId = '',
      type = api.comm.NoticeTypes.BANNER,
      limit = '6',
    }) {
      // const me = this
      const executed = function (res) {
        // console.log('me.banItems: %o', me.banItems)
        if (res.status) {
        }
      }

      this.banParams = notice.getParams({
        siteId,
        type,
        topicId,
        categoryId,
        status: 'publish',
        limit,
        caches: this.banItems,
        executed
      })
    },
    loadBanners (reload = true) {
      this.banParams.reload = reload
      api.http.getItems(this.banParams)
    },
    toBanner (item) {
      const params = {
        noticeId: item.noticeId,
        type: item.type,
        title: item.title
      }

      const name = api.page.noticeDetail
      cookies.set(name, JSON.stringify(params))
      this.$router.push({ name })
    },
  }
}
