
import api from '@/api/co.api'
import dayjs from 'dayjs'

const coNotice = {
  getParams ({
    siteId,
    type = '',
    status = '',
    model = '',
    topicId = '',
    categoryId = '',
    search = '',
    searchType = '',
    limit = '20',
    sort = 'createTime',
    order = 'desc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.CMS_NOTICE_LIST,
      data: {
        siteId,
        type,
        status,
        model,
        topicId,
        categoryId,
        search,
        searchType,
        offset: '0',
        limit,
        sort,
        order
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getDetail ({
    id,
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = {
      url: api.url.CMS_NOTICE_INFO,
      data: {
        noticeId: id
      },
      executing,
      executed,
      transform: transform || this.convertItem
    }
    api.http.getDetail(params)
  },
  sibling ({
    noticeId,
    siteId,
    type = '',
    status = '',
    model = '',
    topicId = '',
    categoryId = '',
    search = '',
    searchType = '',
    sort = 'createTime',
    order = 'desc',
    executing = null,
    executed = null
  }) {
    api.http.pull({
      url: `${api.url.CMS_NOTICE_SIBLING}/${noticeId}`,
      data: {
        noticeId,
        siteId,
        type,
        status,
        model,
        topicId,
        categoryId,
        search,
        sort,
        order
      },
      executing,
      executed
    })
  },
  delete ({
    id,
    executing = null,
    executed = null
  }) {
    api.http.delete({
      url: api.url.MNG_NOTICE_DELETE,
      id,
      executing,
      executed
    })
  },
  update ({
    id,
    data,
    executing = null,
    executed = null
  }) {
    api.http.update({
      url: api.url.MNG_NOTICE_UPDATE,
      id,
      data,
      executing,
      executed
    })
  },
  create ({
    data,
    executing = null,
    executed = null
  }) {
    api.http.create({
      url: api.url.MNG_NOTICE_CREATE,
      data,
      executing,
      executed
    })
  },
  convertToFiles (images) {
    const _files = []
    for (const i in images) {
      const _item = images[i]
      if (_item.type === 'image') {
        _files.push({
          type: _item.type,
          name: _item.name || `图像${i}`,
          url: _item.value,
          size: _item.size || 0
        })
      }
    }
    return _files
  },
  parseAccessory (srcItem) {
    const accessory = []
    const items = api.comm.jsonToArray(srcItem.annex)
    for (const i in items) {
      const item = items[i]
      const obj = api.comm.parsePath(item.url)
      const dst = {
        name: item.name,
        url: item.url,
        size: item.size,
        type: obj.type,
        suffix: obj.suffix,
        dir: obj.dir,
        icon: obj.icon,
        color: obj.color,
        viewable: obj.viewable
      }

      accessory.push(dst)
    }

    return accessory
  },
  convertItem (srcItem) {
    let imageList = []
    let fileList = []
    let content = ''
    if (srcItem.model === 'shop' && srcItem.content) {
      try {
        imageList = JSON.parse(srcItem.content)
        fileList = coNotice.convertToFiles(imageList)
      } catch (error) {
        console.warn('co.notice convertItem content is error! content: %o', srcItem.content)
      }
    } else {
      if (srcItem.content) {
        content = srcItem.content.replace(/<img/gi, '<img style="max-width:100%;height:auto;"')
      }
    }

    const linkex = api.comm.jsonToObject(srcItem.link)
    const accessory = coNotice.parseAccessory(srcItem)
    const customContent = api.comm.jsonToObject(srcItem.customContentView)

    const typeDesc = api.comm.NoticeTypeDescs[srcItem.type] || {}

    const createDate = dayjs(srcItem.createTime).format('YYYY-MM-DD')
    const shortDate = dayjs(srcItem.createTime).format('MM-DD')

    const dstItem = {
      noticeId: srcItem.noticeId,
      title: srcItem.title,
      subtitle: srcItem.subtitle,
      cover: srcItem.cover,
      siteId: srcItem.siteId,
      topicId: srcItem.topicId,
      categoryId: srcItem.categoryId,
      hits: srcItem.hits,
      praise: srcItem.praise,
      collect: srcItem.collect,
      type: srcItem.type,
      typeDesc,
      status: srcItem.status,
      allowComment: srcItem.allowComment || '0',
      link: srcItem.link || '',
      linkex,
      content,
      createTime: srcItem.createTime,
      createDate,
      shortDate,
      model: srcItem.model,
      imageList,
      fileList,
      accessory,
      customContent
    }

    return dstItem
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = coNotice.convertItem(item)
      params.items.push(dstItem)
    })

    return params.items.length
  }
}

export default coNotice
