
import api from '@/api/co.api'
import app from '@/api/co.app'
import notice from '@/api/co.notice'
// import cookies from '@/api/co.libs.cookies'

export default {
  data () {
    return {
      imgParams: null,
      imgItems: [],
      imgPagination: {
        current: 1,
        length: 1,
        totalVisible: 7
      },
      imgLoading: false,
      imgSearch: {
        type: '',
        value: ''
      }
    }
  },
  methods: {
    onSearchKeyDown (ke) {
      if (ke.key === 'Enter') {
        this.onSearch()
      }
    },
    clearSearch () {
      this.toSearch({})
    },
    onSearch () {
      const search = this.imgSearch.value || ''
      let searchType = ''
      if (search) {
        searchType = 'title'
      }
      this.toSearch({ search, searchType })
    },
    toSearch ({
      search = '',
      searchType = ''
    }) {
      const params = this.imgParams
      const data = params.data

      params.reload = true
      data.offset = 0
      data.search = search
      data.searchType = searchType
      api.http.getItems(params)
    },
    changePagination (number) {
      const params = this.imgParams
      const data = params.data
      const limit = parseInt(data.limit, 10) || 9
      const index = parseInt(number, 10) || 1

      this.imgPagination.current = index
      data.offset = (index - 1) * limit
      params.reload = true
      api.http.getItems(params)
    },
    initImageParams ({
      type = 'notice_image',
      topicId = '',
      categoryId = '',
      limit = '9'
    }) {
      const me = this
      const executing = function () {
        me.imgLoading = true
      }

      const executed = function (res) {
        me.imgLoading = false
        if (res.status) {
          const data = me.imgParams.data
          const pagination = me.imgPagination
          const limit = parseInt(data.limit) || 10
          pagination.length = Math.ceil(res.total / limit)
        }
      }

      this.imgParams = notice.getParams({
        siteId: app.init.siteId,
        type,
        topicId,
        categoryId,
        limit,
        caches: this.imgItems,
        executing,
        executed
      })
    },
    loadImages (reload = true) {
      this.imgParams.reload = reload
      api.http.getItems(this.imgParams)
    }
  }
}
