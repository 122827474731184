<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
      :breadcrumbs="banApp.crumbs"
    />
    <land-section
      id="noticecenter"
      space="48"
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
          >
            <div
              class="d-flex flex-column ma-0 pa-0 transparent]"
            >
              <div
                class="d-flex flex-row align-center mb-3"
              >
                <span class="text-lg-h5">
                  {{ navImages.title }}
                </span>
                <v-spacer />
                <v-text-field
                  v-model="imgSearch.value"
                  append-icon="mdi-magnify"
                  filled
                  clear-icon="mdi-close-circle"
                  clearable
                  flat
                  dense
                  solo
                  rounded
                  hide-details="auto"
                  label=""
                  placeholder="查找关键字"
                  type="text"
                  class="ma-0 pa-0"
                  @click:append="onSearch"
                  @click:clear="clearSearch"
                  @keydown="onSearchKeyDown"
                />
              </div>
              <v-divider />
            </div>
          </v-col>
          <v-col
            v-for="(img, index) in imgItems"
            :key="`img_${index}`"
            cols="12"
            lg="4"
            md="6"
          >
            <div class="d-flex flex-column">
              <v-img
                :src="img.cover"
                aspect-ratio="1.46"
                class="grey lighten-2 rounded"
              />
              <div
                class="text-md-h6 text-sm-subtitle-1 co-text-line1 py-2"
              >
                {{ img.title }}
              </div>
            </div>
          </v-col>

          <v-col
            cols="12"
          >
            <div class="d-flex flex-row mt-4">
              <v-pagination
                v-model="imgPagination.current"
                :length="imgPagination.length"
                :total-visible="imgPagination.totalVisible"
                @input="changePagination"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import cookies from '@/api/co.libs.cookies'
  import mixParkBanner from '@/pages/mixins/park/mix.park.banner'
  import mixParkImageTopic from '@/pages/mixins/park/mix.park.image.topic'
  export default {
    metaInfo: { title: '魅力园区' },
    mixins: [mixParkBanner, mixParkImageTopic],
    data () {
      return {
        navImages: {}
      }
    },
    created () {
      const name = api.page.parkImages.name
      const nav = JSON.parse(cookies.get(name))
      Object.assign(this.navImages, nav)

      this.initBannerParams({
        siteId: app.init.siteId,
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()

      this.initImageParams({
        type: nav.type,
        topicId: nav.topicId,
        categoryId: nav.categoryId
      })

      this.setCrumbs(nav)

      this.loadImages()
    }
  }
</script>
